interface ComponentsVersion {
    widgetComponentsVersion: string;
}

export enum ResponseStatus {
    SUCCESS = 0,
    LIVE_CHAT_PRODUCT_NOT_AVAILABLE = 4001,
    WIDGET_CONFIG_NOT_FOUND = 4002,
    // other statuses aren't needed here
}

export class Constants {
    /**
     * The URL to get the versions of the LiveChat Frontends (artifacts).
     */
    static readonly getArtifactVersionsUrl = (domain: string) => `https://${domain}/livechat/api/artifacts`;

    /**
     * The latest version of the LiveChat Frontends.
     * It's used as a fallback in case of an error during the loading of the specific version.
     * It's a name of the folder in where the widget.js is located.
     */
    static readonly LATEST = '__latest__';

    /**
     * The base URL of the LiveChat Frontends.
     * The whole logic in the ternary operator below is replaced during build.
     */
    static readonly LIVECHAT_BASE_URL = (() => {
        switch (import.meta.env?.VITE_BUILD_ENV) {
            case 'local':
                return 'http://localhost:8081';
            case 'development':
            case 'io':
                return 'https://livechat.ioinfobip.com';
            case 'production':
            default:
                return 'https://livechat.infobip.com';
        }
    })();

    static readonly LIVECHAT_CONTENT_TYPE = 'application/vnd.infobip.livechat+json';

    static readonly STORAGE_KEYS = {
        domain: 'LC:domain',
        urlPath: 'LC:urlPath',
        widgetInfoResponse: 'LC:widgetInfo_resp',
        widgetInfo_timestamp: 'LC:widgetInfo_timestamp',
    };

    /**
     * Function to create HTML for the widget iframe from the template.
     * Previously, this HTML was created in the widget-components project with hardcoded URLS (in src, href).
     * @param widgetComponentsVersion - (string) version to use
     */
    static readonly WIDGET_IFRAME_HTML_CREATE = ({ widgetComponentsVersion }: ComponentsVersion) => {
        const WIDGET_IFRAME_HTML = `<html lang="en">
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <script defer="defer" src="app.bundle.js"></script>
        <link href="app.css" rel="stylesheet">
    </head>
    <body>
        <div id="widget"></div>
    </body>
    </html>`;

        return Constants.WIDGET_IFRAME_REPLACEMENTS({ widgetComponentsVersion }).reduce(
            (acc, { searchValue, replaceValue }) => acc.replace(searchValue, replaceValue),
            WIDGET_IFRAME_HTML
        );
    };

    private static readonly WIDGET_IFRAME_REPLACEMENTS = ({ widgetComponentsVersion }: ComponentsVersion) => {
        return [
            {
                searchValue: 'app.bundle.js',
                replaceValue: `widget-components/${widgetComponentsVersion}/app.bundle.js`,
            },
            {
                searchValue: 'app.css',
                replaceValue: `widget-components/${widgetComponentsVersion}/app.css`,
            },
        ];
    };

    static readonly ERRORS = {
        LIVE_CHAT_PRODUCT_NOT_AVAILABLE: {
            code: 1950,
            message: `Error: Live Chat is not allowed for that account`,
        },
        WIDGET_NOT_FOUND: {
            code: 1004,
            message: 'Widget with provided ID not found',
        },
    };
}
