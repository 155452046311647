/* eslint-disable no-console */

/**
 * Logging is disabled by default. To enable them, call `localStorage['LC:logging'] = true`.
 */
export class Logger {
    private static isLoggingEnabled = () => window.localStorage.getItem('LC:logging') === 'true';

    static log(msg?: any, ...optionalParams: any[]) {
        if (this.isLoggingEnabled()) {
            console.log(`LC-loader » ${msg}`, ...optionalParams);
        }
    }

    static error(msg?: any, ...optionalParams: any[]) {
        if (this.isLoggingEnabled()) {
            console.error(`LC-loader » ${msg}`, ...optionalParams);
        }
    }
}
